import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { NetworkState } from '@/store/modules/network/types'

import { dcomm, dvm, bintools, actChain, infoApi, athChain } from '@/DCOMM'
import { DcommNetwork } from '@/js/DcommNetwork'
import { explorer_api } from '@/explorer_api'
import { BN } from '@dcomm-tech/dcomm-js'
import { getPreferredHRP } from '@dcomm-tech/dcomm-js/dist/utils'
import router from '@/router'
import { web3 } from '@/evm'
import { setSocketNetwork } from '../../../providers'
import { getConfigFromUrl, setNetworkAsync } from '@dcomm-tech/wallet-sdk'
const network_module: Module<NetworkState, RootState> = {
    namespaced: true,
    state: {
        status: 'disconnected', // disconnected | connecting | connected
        networks: [],
        networksCustom: [],
        selectedNetwork: null,
        txFee: new BN(0),
    },
    mutations: {
        addNetwork(state, net: DcommNetwork) {
            state.networks.push(net)
        },
    },
    getters: {
        allNetworks(state) {
            return state.networks.concat(state.networksCustom)
        },
    },
    actions: {
        addCustomNetwork({ state, dispatch }, net: DcommNetwork) {
            // Check if network alerady exists
            const networks = state.networksCustom
            // Do not add if there is a network already with the same url
            for (let i = 0; i < networks.length; i++) {
                const url = networks[i].url
                const name = networks[i].name
                if (net.url === url) {
                    this.dispatch('Notifications/add', {
                        type: 'error',
                        title: 'Failed',
                        message: 'Network with same url already exist',
                    })
                    return
                }
                if (net.name.toLowerCase() === name.toLowerCase()) {
                    this.dispatch('Notifications/add', {
                        type: 'error',
                        title: 'Failed',
                        message: 'Network with same name already exist',
                    })
                    return
                }
            }
            state.networksCustom.push(net)
            dispatch('save')
        },

        editCustomNetwork({ state, dispatch }, net: DcommNetwork) {
            const networks = state.networksCustom
            let index: number = -1
            const currentNetIndex = networks.findIndex((_n, key) => {
                if (_n.name == net.name) {
                    _n.url = net.url
                    _n.explorerUrl = net.explorerUrl
                    _n.explorerSiteUrl = net.explorerSiteUrl
                    index = key
                }
            })

            if (index < 0) return

            state.networksCustom[currentNetIndex] = net
            dispatch('save')
            this.dispatch('Notifications/add', {
                type: 'success',
                title: 'Successful',
                message: 'Network changes saved',
            })
        },

        async removeCustomNetwork({ state, dispatch }, net: DcommNetwork) {
            const index = state.networksCustom.indexOf(net)
            state.networksCustom.splice(index, 1)
            await dispatch('save')
        },
        saveSelectedNetwork({ state }) {
            const data = JSON.stringify(state.selectedNetwork?.url)
            localStorage.setItem('network_selected', data)
        },
        async loadSelectedNetwork({ dispatch, getters }): Promise<boolean> {
            const data = localStorage.getItem('network_selected')
            if (!data) return false
            try {
                // let net: DcommNetwork = JSON.parse(data);
                const nets: DcommNetwork[] = getters.allNetworks

                for (let i = 0; i < nets.length; i++) {
                    const net = nets[i]
                    if (JSON.stringify(net.url) === data) {
                        dispatch('setNetwork', net)
                        return true
                    }
                }
                return false
            } catch (e) {
                return false
            }
        },
        async setDefaultNetwork({ state, dispatch }) {
            await dispatch('setNetwork', state.networks[0])
        },

        // Save custom networks to local storage
        save({ state }) {
            const data = JSON.stringify(state.networksCustom)
            localStorage.setItem('networks', data)
        },
        // Load custom networks from local storage
        load({ dispatch }) {
            const data = localStorage.getItem('networks')

            if (data) {
                const networks: DcommNetwork[] = JSON.parse(data)

                networks.forEach((n) => {
                    const newCustom = new DcommNetwork(
                        n.name,
                        n.url,
                        //@ts-ignore
                        parseInt(n.networkId),
                        n.explorerUrl,
                        n.explorerSiteUrl,
                        n.readonly
                    )
                    dispatch('addCustomNetwork', newCustom)
                })
            }
        },
        async setNetwork({ state, dispatch, commit, rootState }, net: DcommNetwork) {
            state.status = 'connecting'

            // Chose if the network should use credentials
            await net.updateCredentials()
            dcomm.setRequestConfig('withCredentials', net.withCredentials)
            dcomm.setAddress(net.ip, net.port, net.protocol)
            dcomm.setNetworkID(net.networkId)

            // Reset transaction history
            commit('History/clear', null, { root: true })

            // Query the network to get network id
            const chainIdX = await infoApi.getBlockchainID('AST')
            const chainIdP = await infoApi.getBlockchainID('ATH')
            const chainIdC = await infoApi.getBlockchainID('ACT')

            dvm.refreshBlockchainID(chainIdX)
            dvm.setBlockchainAlias('AST')
            athChain.refreshBlockchainID(chainIdP)
            athChain.setBlockchainAlias('ATH')
            actChain.refreshBlockchainID(chainIdC)
            actChain.setBlockchainAlias('ACT')

            dvm.getDCMAssetID(true)
            athChain.getDCMAssetID(true)
            actChain.getDCMAssetID(true)

            state.selectedNetwork = net
            dispatch('saveSelectedNetwork')

            // Update explorer api
            explorer_api.defaults.baseURL = net.explorerUrl

            // Set web3 Network Settings
            const web3Provider = `${net.protocol}://${net.ip}:${net.port}/ext/bc/ACT/rpc`
            web3.setProvider(web3Provider)

            // Set socket connections
            setSocketNetwork(net)

            commit('Assets/removeAllAssets', null, { root: true })
            await dispatch('Assets/updateDcommAsset', null, { root: true })

            // If authenticated
            if (rootState.isAuth) {
                // Go back to wallet page
                router.replace('/wallet')
                for (let i = 0; i < rootState.wallets.length; i++) {
                    const w = rootState.wallets[i]
                    w.onnetworkchange()
                }
            }

            await dispatch('Assets/onNetworkChange', net, { root: true })
            dispatch('Assets/updateUTXOs', null, { root: true })
            dispatch('Authority/update', null, { root: true })
            dispatch('Authority/updateMinStakeAmount', null, { root: true })
            dispatch('updateTxFee')
            // Update tx history
            dispatch('History/updateTransactionHistory', null, { root: true })

            // Set the SDK Network
            const sdkNetConf = await getConfigFromUrl(net.getFullURL())
            await setNetworkAsync({
                ...sdkNetConf,
                explorerURL: net.explorerUrl,
                explorerSiteURL: net.explorerSiteUrl,
            })
            // state.isConnected = true;
            state.status = 'connected'
            return true
        },

        async updateTxFee({ state }) {
            const txFee = await infoApi.getTxFee()
            state.txFee = txFee.txFee
            dvm.setTxFee(txFee.txFee)
        },

        async init({ state, commit, dispatch }) {
            const mainnet = new DcommNetwork(
                'Mainnet',
                'https://api-mainnet.dcomm.network',
                1,
                'https://indexer-mainnet.dcomm.network',
                'https://explorer.dcomm.network',
                true
            )

            const canary = new DcommNetwork(
                'Canary',
                'https://dcomm-validators.testnet.zeeve.net',
                6,
                'https://dcomm-indexer-dev.testnet.zeeve.net',
                'https://dcomm-explorer.testnet.zeeve.net',
                true
            )

            const goldcoast = new DcommNetwork(
                'GoldCoast',
                'https://api-goldcoast.dcomm.network',
                3,
                'https://indexer-goldcoast.dcomm.network',
                'https://explorer.dcomm.network',
                true
            )

            const melbourne = new DcommNetwork(
                'Melbourne',
                'https://api-melbourne.dcomm.network',
                2,
                'https://indexer-melbourne.dcomm.network',
                'https://explorer.dcomm.network',
                true
            )
            // Load custom networks if any
            try {
                await dispatch('load')
            } catch (e) {
                console.error(e)
            }
            commit('addNetwork', mainnet)
            commit('addNetwork', goldcoast)
            commit('addNetwork', canary)
            try {
                const isSet = await dispatch('loadSelectedNetwork')
                if (!isSet) {
                    await dispatch('setNetwork', state.networks[0])
                }
                return true
            } catch (e) {
                console.log(e)
                state.status = 'disconnected'
            }
        },
    },
}

export default network_module
